import React from "react";
import styles from "./ScrollTopButton.module.scss";

const ScrollTopButton = () => {
  const handleClick = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    return false;
  };

  return (
    <button
      onClick={handleClick}
      aria-label='Scroll to top of the page'
      className={styles.button}>
      <i className={`fa-solid fa-chevron-up ${styles.icon}`}></i>
    </button>
  );
};

export default ScrollTopButton;
