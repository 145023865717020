import React, { createContext, useContext } from "react";

// Create a context for the base path
const BasePathContext = createContext("/");

export const useBasePath = () => {
  return useContext(BasePathContext);
};

export const BasePathProvider = ({ children, basePath }) => {
  return (
    <BasePathContext.Provider value={basePath}>
      {children}
    </BasePathContext.Provider>
  );
};
