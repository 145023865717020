import React from "react";
import styles from "./Copyright.module.scss";
import { SITE_NAME, CURRENT_YEAR } from "../../Constants/constants";

const Copyright = () => {
  return (
    <small className={styles.copyright}>
      <p>
        <span className={styles.copyright__year}>
          {SITE_NAME} &copy; {CURRENT_YEAR}
        </span>
        <span className={styles.copyright__separator}> | </span>
        <span className={styles.copyright__company}>
          An{" "}
          <a href='https://www.iteuropa.com' className={styles.copyright__link}>
            IT Europa Media &amp; Intelligence Limited
          </a>{" "}
          Event
        </span>
      </p>
    </small>
  );
};

export default Copyright;
