export const FooterMenu = [
  {
    label: "Privacy Policy",
    path: "https://iteuropa.com/privacy-policy",
  },
  {
    label: "Cookie Policy",
    path: "https://iteuropa.com/cookie-policy",
  },
  {
    label: "Sitemap",
    path: "sitemap.xml",
  }
];
