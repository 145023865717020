import { Link as RouterLink, useLocation } from "react-router-dom";
import styles from "./Button.module.scss";

function Button({ to, type = "primary", className, children, onClick }) {
  const location = useLocation();
  const isHomePage = location.pathname === "/2024";

  const handleAnchorClick = (e) => {
    if (to && to.startsWith("#")) {
      e.preventDefault();
      const anchor = to.substring(1);
      const element = document.getElementById(anchor);
      if (element) element.scrollIntoView({ behavior: "smooth" });

      // Update the URL in the address bar without adding a new history entry
      window.history.replaceState(null, null, to);
    }
  };

  if (to) {
    if (to.startsWith("#") || to.startsWith("/#")) {
      return (
        <a
          href={to}
          className={`${styles.button} ${className ? className : ""}`}
          onClick={(e) => {
            handleAnchorClick(e);
            if (onClick) onClick(e);
          }}>
          {children}
        </a>
      );
    }

    return (
      <RouterLink
        to={to}
        className={`${styles.button} ${className ? className : ""}`}
        onClick={(e) => {
          if (isHomePage && to.startsWith("/#")) {
            handleAnchorClick(e);
          }
          if (onClick) onClick(e);
        }}>
        {children}
      </RouterLink>
    );
  }

  return null;
}

export default Button;
