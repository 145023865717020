export const MAINTENANCE_MODE = false;
export const HOLDING_MODE = true;

export const SITE_NAME = "Channel-Sec";
export const EVENT_VENUE = "London Marriott Grosvenor Square";
export const EVENT_ADDRESS = "Grosvenor Square, London, GB W1K 6JP";
export const EVENT_DATE_STRING = "2024-06-26";
export const EVENT_SLOGAN = "The Security Event for the European IT Channel"
export const EVENT_REGISTRATION = "https://www.delegateselect.com/event/channel-sec/";
export const EVENT_LOGIN = "https://www.delegateselect.com/event/channel-sec/login.html";

const today = new Date();
export const CURRENT_YEAR = today.getFullYear();

/* Provide various event date formats */
const eventDate = new Date(EVENT_DATE_STRING);

export const EVENT_DATE = eventDate.toLocaleDateString('en-GB', {
  weekday: 'long',  // Thursday
  month: 'long',    // May
  day: '2-digit',   // 18
  year: 'numeric'   // 2023
});

export const EVENT_YEAR = eventDate.toLocaleString('en-GB', {
  year: 'numeric'
})

// Create a Date object for the previous year
const previousYearDate = new Date(eventDate);
previousYearDate.setFullYear(eventDate.getFullYear() - 1);

export const PREVIOUS_EVENT_YEAR = previousYearDate.toLocaleString('en-GB', {
  year: 'numeric'  // 2023
});


function getOrdinalSuffix(day) {
  if (day % 10 === 1 && day !== 11) {
    return 'st';
  } else if (day % 10 === 2 && day !== 12) {
    return 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    return 'rd';
  }
  return 'th';
}

const weekdayName = eventDate.toLocaleDateString('en-GB', {
  weekday: 'long',
});
const monthName = eventDate.toLocaleDateString('en-GB', {
  month: 'long',
});
const yearValue = eventDate.toLocaleDateString('en-GB', {
  year: 'numeric',
});
const dayWithoutSuffix = eventDate.toLocaleDateString('en-GB', {
  day: '2-digit',
});

const dayWithSuffix = `${dayWithoutSuffix}${getOrdinalSuffix(Number(dayWithoutSuffix))}`;

export const EVENT_DATE_SUFFIXED = `${weekdayName} ${dayWithSuffix} ${monthName} ${yearValue}`;