import React, { useEffect, Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Menu, { FooterMenu } from "./Components/Menu";

import Footer from "./Components/Footer";
import ScrollTopButton from "./Components/ScrollTopButton/ScrollTopButton";
import Copyright from "./Components/Copyright/Copyright";
import CookieConsent from "./Components/CookieConsent";

import MailtoUI from "/node_modules/mailtoui/dist/mailtoui-min.js"; // adjust based on the actual path
import Aos from "aos";

import "./style.scss";
import "aos/dist/aos.css";
import { MAINTENANCE_MODE, HOLDING_MODE } from "./Constants/constants";
import { BasePathProvider } from "./Context/BasePathContext"; // import the context provider

const Home = React.lazy(() => import("./Pages/Home"));
const SponsorPage = React.lazy(() => import("./Pages/Sponsorship"));
const AgendaPage = React.lazy(() => import("./Pages/Agenda"));
const MaintenancePage = React.lazy(() => import("./Pages/Maintenance"));
const HoldingPage = React.lazy(() => import("./Pages/Holding"));

function App() {
  useEffect(() => {
    MailtoUI.run();
    setTimeout(() => {
      Aos.init({
        offset: 200,
      });
    }, 500); // initializing AOS after 500ms
  }, []);

  const handleConsent = () => {
    // Initialize Google Analytics or other scripts here
    console.log("User consented!");
  };

  const handleDeny = () => {
    console.log("User denied consent!");
    // Optionally handle any cleanup or additional actions
  };

  const DefaultLayout = () => (
    <div>
      <ScrollToTop />
      <Suspense fallback={<div className="page">Loading...</div>}>
        <Outlet />
      </Suspense>
      <Footer>
        <Menu className={"footer__menu"} items={FooterMenu} />
        <ScrollTopButton />
        <Copyright />
      </Footer>
      <CookieConsent onConsent={handleConsent} onDeny={handleDeny} />
    </div>
  );

  const basePath = HOLDING_MODE ? "/2024" : "/";

  return (
    <div>
      <Helmet>
        {/* <meta
          http-equiv="Content-Security-Policy"
          content="
            default-src 'self' https://fonts.gstatic.com https://api.channel-sec.com;
            script-src 'self' https://www.google.com/recaptcha/api.js https://www.google.com/recaptcha/api/siteverify https://channel-sec.com/static/js/ *;
            style-src 'self' https://cdnjs.cloudflare.com https://fonts.googleapis.com 'unsafe-inline';
            font-src 'self' https://cdnjs.cloudflare.com https://fonts.gstatic.com;
            media-src 'self' https://player.vimeo.com;
            frame-src 'self' https://www.google.com/maps/embed https://player.vimeo.com/video/;
        "
        /> */}
      </Helmet>

      <BasePathProvider basePath={basePath}>
        <Suspense fallback={<div className="page">Loading...</div>}>
          <Routes>
            {MAINTENANCE_MODE ? (
              <Route path="/" element={<MaintenancePage />} />
            ) : HOLDING_MODE ? (
              <>
                <Route path="/" element={<HoldingPage />} />
                <Route path="/2024" element={<DefaultLayout />}>
                  <Route index element={<Home />} />
                  <Route path="sponsorship" element={<SponsorPage />} />
                  <Route path="agenda" element={<AgendaPage />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : (
              <Route path="/" element={<DefaultLayout />}>
                <Route index element={<Home />} />
                <Route path="sponsorship" element={<SponsorPage />} />
                <Route path="agenda" element={<AgendaPage />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            )}
          </Routes>
        </Suspense>
      </BasePathProvider>
    </div>
  );
}

export default App;
